<template>
  <v-container fluid class="mt-0">
    <v-row justify="center" >
      <v-col cols="12" md="10" lg="9" >
        <v-select :items="user.data.kinder" item-text="vorname" return-object v-model="child" label="Kind" rounded filled></v-select>
        <v-row class="pa-2" justify="center">
          <v-col cols="12" v-if="angebote.length == 0" align-self="center" class="mt-5 text-center">
            <h2 class="text-center">
              Es gibt derzeit leider keine Angebote für die Sportarten von {{child.vorname}}.
            </h2>
            <v-btn class="mt-5" rounded color="primary" to="/user/entdecken">Andere Sportarten entdecken</v-btn>
          </v-col>
          <v-col cols="12" md="5" v-for="item in angebote" :key="item.id"> 
            <sportangebotcard :item="item" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sportangebotcard from '@/components/Sportangebot/card.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Sportangebote',
  data() {
    return {
      selected_offer: { active: false },
      tab: null,
      child: ''
    }
  },
  components: {
    sportangebotcard
  },
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      db: 'db',
      sportarten: 'sportarten',
      sportangebote: 'sportangebote',
    }),
    angebote(){
      if(this.child){
        var kind = this.child
        return this.sportangebote.all.filter(item => kind.sportarten.includes(item.sportart.name) && item.active == true && item.altersgruppen.includes(kind.alter?.toString()) )
      }
      return []
    }
  },
  mounted(){
    this.child = this.user.data.kinder?.length > 0 ? this.user.data.kinder[0] : ''
  },
  methods: {
    verein(id) {
      return this.vereine.all.find((verein) => verein.id == id)
    },
    sportart(name) {
      return this.sportarten.all.find((art) => art.name == name)
    },
    parseDateTime(obj) {
      var datum = obj.datum.split('-')
      var uhrzeit = obj.uhrzeit.split(':')
      return (
        datum[2] +
        '.' +
        datum[1] +
        '.' +
        datum[0] +
        ' um ' +
        uhrzeit[0] +
        ':' +
        uhrzeit[1] +
        ' Uhr'
      )
    },
    parseTime(obj) {
      var uhrzeit = obj.uhrzeit.split(':')
      return uhrzeit[0] + ':' + uhrzeit[1] + ' Uhr'
    },
    parseDate(obj) {
      var datum = obj.datum.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    getAge(DOB) {
      if(DOB){
        var d_o_b = DOB.split('.')
        if(d_o_b.length == 3){
          var today = new Date();
          var birthDate = new Date(d_o_b[2]+'-'+d_o_b[1]+'-'+d_o_b[0]);
          var age = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age = age - 1;
          }
          return age;
        }
      }
      return false
    }
  },
}
</script>
